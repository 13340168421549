import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { DeleteResponse } from "@/api/image-delete/response";
import * as ImageAPI from "@/api/image-delete";
import { isSuccess } from "@/api/response";
import { DeleteRequest } from "@/api/image-delete/request";

const MODULE_NAME = "image/delete";

/**
 * 画像ダイアログ画像履歴削除API（/image-delete）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Delete extends VuexModule {
  // state
  deleteResponse: DeleteResponse = {} as DeleteResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.deleteResponse);
  }

  get getMessage() {
    return this.deleteResponse.message;
  }

  // MutationActions
  @MutationAction
  async delete(request: DeleteRequest) {
    const deleteResponse = await ImageAPI.deleteImage(request);
    return {
      deleteResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      deleteResponse: {} as DeleteResponse
    };
  }
}

export default getModule(Delete);

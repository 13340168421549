import service from "@/api/service";
import { DeleteResponse } from "@/api/image-delete/response";
import { DeleteRequest } from "@/api/image-delete/request";

/**
 * 画像ダイアログ画像履歴削除APIをコールします。
 *
 * @return DeleteResponse
 */
export async function deleteImage(request: DeleteRequest) {
  const response = await service.post("/image-delete", request);
  return response.data as DeleteResponse;
}

import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse, GetMenu } from "@/api/sub-menu/response";
import { GetRequest } from "@/api/sub-menu/request";
import * as GlobalFooterAPI from "@/api/sub-menu";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "sub-menu/get";

/**
 * global-footer一覧・検索API（/get-sub-menu）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  searchResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getItems() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.submenu;
    } else {
      return [] as GetMenu[];
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const searchResponse = await GlobalFooterAPI.get(getRequest);
    return {
      searchResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);

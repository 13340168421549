import { Component, Mixins, Prop } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { GetRequestLayout, RegisterRequest } from "@/api/app-layout/request";
import { GetLayout, GetResult } from "@/api/app-layout/response";
import AppLayoutGet from "@/store/app-layout/get";
import AppLayoutRegister from "@/store/app-layout/register";
import Flash from "@/store/common/flash";
import WindowOpen from "@/utils/window-open";

@Component({ components: { UIFormRow } })
export default class NewEditLayout extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  inputParams = {
    /** 店舗ID */
    id: 0 as number,
    /** 店舗名 */
    name: "" as string,
    /** アプリ名 */
    appName: "" as string,
    /** デザインID */
    designsId: null as number | null,
    /** レイアウトタイプ */
    topType: 0 as number,
    /** 書き込み権限 */
    writeFlg: 0 as number,
    /** レイアウトタイプ情報配列 */
    layout: [] as GetLayout[]
  } as GetResult;

  get agreementsType() {
    return this.$route.query.agreementsType ? this.$route.query.agreementsType : "";
  }

  async created() {
    const request = { id: Number(this.shopId) } as GetRequestLayout;
    await AppLayoutGet.get(request);
    if (!AppLayoutGet.isSuccess) {
      await Flash.setErrorNow({
        message: AppLayoutGet.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.syncInputParams();
  }

  async beforeDestroy() {
    await AppLayoutGet.clearResponse();
    await AppLayoutRegister.clearResponse();
  }

  goBack() {
    this.$router.back();
  }

  async onSave() {
    await this.callRegisterApi(0);
    if (AppLayoutRegister.isSuccess) {
      await this.redirectWithSuccessAlert(
        "レイアウト設定を保存しました。",
        "/appdesign"
      );
    }
  }

  async preview() {
    await this.callRegisterApi(1);
    if (AppLayoutRegister.isSuccess) {
      WindowOpen.preview(AppLayoutRegister.getPreviewUrl);
    }
  }

  private async callRegisterApi(isPreview: number) {
    await Flash.clear();
    await AppLayoutRegister.register(this.createRegisterRequest(isPreview));
    if (!AppLayoutRegister.isSuccess) {
      await Flash.setErrorNow({
        message: AppLayoutRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  private syncInputParams() {
    this.inputParams = {
      ...AppLayoutGet.getResults
    };
  }

  private createRegisterRequest(isPreview: number) {
    return {
      id: Number(this.shopId),
      designsId: this.inputParams.designsId,
      topType: this.inputParams.topType,
      isPreview: isPreview
    } as RegisterRequest;
  }
}

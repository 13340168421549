import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse, GetFooter } from "@/api/global-footer/response";
import { GetRequest } from "@/api/global-footer/request";
import * as GlobalFooterAPI from "@/api/global-footer";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "global-footer/get";

/**
 * グローバルフッター一覧・検索API（/get-global-footer）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  searchResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getItems() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.gf.concat(
        this.searchResponse.results.footer
      );
    } else {
      return [] as GetFooter[];
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const searchResponse = await GlobalFooterAPI.get(getRequest);
    return {
      searchResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);

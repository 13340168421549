import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"drawer-category-name"},[_vm._v("アピール")]),_c(VCard,{staticClass:"pa-5"},[_c('ul-content-header',{attrs:{"heading-main":_vm.headingMain,"heading-sub":_vm.headingSub}}),_c(VRow,[_c(VCol,{staticClass:"pt-0"},[_c('ul-breadcrumbs',{attrs:{"items":_vm.breadCrumbs}})],1)],1),_c(VTabs,{attrs:{"grow":"","show-arrows":"","color":"yellow darken-4"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c(VTabsSlider,{attrs:{"color":"#279bd8"}}),_vm._l((_vm.tabItems),function(item){return _c(VTab,{key:item.title,class:item.hidden ? 'd-none' : '',attrs:{"disabled":item.disabled}},[(!(item.hidden))?_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()])})],2),_c(VTabsItems,{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.tabItems),function(item){return _c(VTabItem,{key:item.title},[_c(item.component,{tag:"component",attrs:{"shopId":_vm.shopId,"agreements":_vm.agreements,"isWebAgreementOnly":_vm.isWebAgreementOnly,"isLineAgreementOnly":_vm.isLineAgreementOnly,"isNotAgreement":_vm.isNotAgreement}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
















































































































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { UlFileRegisiterTabType } from "@/utils/enums";
import ImageGet from "@/store/image/get";
import { GetRequest } from "@/api/image-get/request";
import ImageConversion from "@/store/image/conversion";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { FormatItem, Icon, PhotoItem } from "@/api/image-get/response";
import { ConversionRequest } from "@/api/image-conversion/request";
import ImageDelete from "@/store/image/delete";
import { DeleteRequest } from "@/api/image-delete/request";

@Component
export default class UlFileRegister extends Vue {
  @Prop({ default: UlFileRegisiterTabType.Photo })
  tabType!: UlFileRegisiterTabType;
  @Prop() pixelSizeDescription?: string;
  @Prop() fileTypeDescription?: string;
  @Prop() fileSizeDescription?: string;
  @Prop() allowedTypes!: string[];
  @Prop() initImageUrl?: string;
  //NEW_DEV-1999 cyber start
  @Prop() resetImage?: number;
  //NEW_DEV-1999 cyber end
  @Prop({default: "img"}) fileType?: string;
  // 20201228 C81	_デジタルチケット-新規登録・編集画面 -3_(質問管理表_No.24) presq ishida start
  @Prop() editFlg?: string;
  // 20201228 C81	_デジタルチケット-新規登録・編集画面 -3_(質問管理表_No.24) presq ishida end
  @Prop() imageShopId?: string;

  @Emit()
  public setFile(data: string, id: number | null = null) {}

  @Emit()
  public unsetFile() {}

  @Watch("initImageUrl")
  watchInitImageUrl() {
    if (this.initImageUrl == null || this.initImageUrl.length <= 0) {
      this.selectedImageUrl = "";
    }
    if (this.initImageUrl && this.initImageUrl.length > 0) {
      this.selectedImageUrl = this.initImageUrl;
    }
  }
  //NEW_DEV-1999 cyber start
  @Watch("resetImage")
  watchresetImage() {
    if (this.initImageUrl == null || this.initImageUrl.length <= 0 || this.initImageUrl == "") {
      this.selectedImageUrl = "";
      this.onClickDeleteUploadedImage();
      this.onClickDeleteSelectedImage();
    }
  }
  //NEW_DEV-1999 cyber end

  canDrop = false;
  dialog = false;

  selectedImageUrl: string = "";
  selectedImageId: number | null = null;

  tabTypePhoto = UlFileRegisiterTabType.Photo;
  tabTypeIcon = UlFileRegisiterTabType.Icon;
  tabTypeFormat = UlFileRegisiterTabType.Format;

  tab = null;

  get photos() {
    return this.parsePhoto();
  }

  get icons() {
    return this.parseIcon();
  }

  get formats() {
    return this.parseFormat();
  }

  get acceptType() {
    var acceptTypeText = '*';
    switch (this.fileType) {
      case 'img':
        acceptTypeText = 'image/*';
        break;
    }
    return acceptTypeText;
  }

  private createRequest(tabType: UlFileRegisiterTabType) {
    var imageType = 0;
    switch (tabType) {
      case UlFileRegisiterTabType.Photo:
        imageType = 2;
        break;
      case UlFileRegisiterTabType.Format:
        imageType = 1;
        break;
      case UlFileRegisiterTabType.Icon:
        imageType = 0;
        break;
    }
    var imageShopId = null;
    if (this.imageShopId) {
      imageShopId = this.imageShopId
    }
    return { imageType: imageType, imageShopId: imageShopId } as GetRequest;
  }

  async fetchImages(tabType: UlFileRegisiterTabType) {
    const request = this.createRequest(tabType) as GetRequest;
    await ImageGet.get(request);
    if (!ImageGet.isSuccess) {
      await Flash.setErrorNow({
        message: ImageGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  async convertBase64String(url: string) {
    const request = { url: url } as ConversionRequest;
    await ImageConversion.conversion(request);
    if (!ImageConversion.isSuccess) {
      await Flash.setErrorNow({
        message: ImageConversion.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  async deleteUploadedImage(imageId: number) {
    const request = { imageId: imageId } as DeleteRequest;
    await ImageDelete.delete(request);
    if (!ImageDelete.isSuccess) {
      await Flash.setErrorNow({
        message: ImageDelete.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
  }

  mounted() {
    // FIXME: mount後でないとwatcherが動かないので、ここでもコールする
    this.watchInitImageUrl();
    //NEW_DEV-1999 cyber start
    if(this.resetImage){
      this.watchresetImage();
    }
    //NEW_DEV-1999 cyber end
  }

  private onDrag(status: boolean) {
    this.canDrop = status;
  }

  private onDrop(dragEvent: DragEvent) {
    this.canDrop = false;
    const dataTransfer = dragEvent.dataTransfer;
    if (dataTransfer) {
      const files = dataTransfer.files;
      this.registerFiles(files);
    }
  }

  private onFileChange(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      this.registerFiles(target.files);
    }
    this.fileTypeCheck(target.files);
  }

  private fileTypeCheck(files: FileList) {
    files.forEach(function (f) {
      if (this.fileType == 'img' && !f.type.match(/image/)) {
        Flash.setErrorNow({
          message: '画像ファイルを選択してください。',
          showReloadButton: true
        } as ErrorAlert);
      }
    }.bind(this));
  }

  private parsePhoto() {
    if (ImageGet.getResults != null) {
      return [
        {
          photoName: ImageGet.getResults.photoName,
          photoItems: ImageGet.getResults.photoItems
        }
      ];
    }
    return null;
  }

  private parseIcon() {
    var icons = null;
    if (this.tabType === this.tabTypeIcon && ImageGet.getResults != null) {
      return ImageGet.getResults.iconItems;
    }
    return icons;
  }

  private parseFormat() {
    var formats = null;
    if (this.tabType === this.tabTypeFormat && ImageGet.getResults != null) {
      return [
        {
          formatName: ImageGet.getResults.formatName,
          formatItems: ImageGet.getResults.formatItems
        }
      ];
    }
    return formats;
  }

  private registerFiles(files: FileList) {
    if (!this.isValidFileType(files[0].type)) {
      return;
    }

    this.resetPhotoSelectionState();
    this.resetIconSelectionState();
    this.resetFormatSelectionState();

    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result == "string") {
        this.selectedImageUrl = reader.result;
        this.setFile(this.selectedImageUrl, this.selectedImageId);
        this.selectedImageId = null;
      }
    };
    reader.readAsDataURL(files[0]);
  }

  private isValidFileType(fileType: string) {
    return this.allowedTypes.includes(fileType);
  }

  private isImageFile() {
    return this.allowedTypes
      .join()
      .toString()
      .includes("image");
  }

  private async onOpenDialog() {
    this.dialog = true;
    await this.fetchImages(this.tabType);
  }

  private async onClickComplete() {
    this.dialog = false;
    this.setFile(this.selectedImageUrl, this.selectedImageId);
    this.selectedImageId = null;
  }

  private async onClickDeleteUploadedImage(photoItem: PhotoItem) {
    await this.deleteUploadedImage(photoItem.id);
    if (ImageDelete.isSuccess) {
      if (this.photos != null) {
        const index = this.photos[0].photoItems.findIndex(
          item => item == photoItem
        );
        this.photos[0].photoItems.splice(index, 1);
      }
    }
  }

  private onClickDeleteSelectedImage() {
    (this.$refs.inputUpload as HTMLInputElement).value = "";
    this.resetPhotoSelectionState();
    this.resetIconSelectionState();
    this.resetFormatSelectionState();
    this.selectedImageUrl = "";
    this.selectedImageId = null;
    this.unsetFile();
  }

  private isSelected(isSelected: boolean) {
    return isSelected ? "selected" : "";
  }

  private async onClickPhoto(photoItem: PhotoItem) {
    this.resetPhotoSelectionState();
    this.resetIconSelectionState();
    this.resetFormatSelectionState();
    this.updateSelectedImage(photoItem, UlFileRegisiterTabType.Photo);
  }

  private async onClickIcon(iconItem: Icon) {
    this.resetPhotoSelectionState();
    this.resetIconSelectionState();
    this.resetFormatSelectionState();
    this.updateSelectedImage(iconItem, UlFileRegisiterTabType.Icon);
  }

  private async onClickFormat(formatItem: FormatItem) {
    this.resetPhotoSelectionState();
    this.resetIconSelectionState();
    this.resetFormatSelectionState();
    this.updateSelectedImage(formatItem, UlFileRegisiterTabType.Format);
  }

  private updateSelectedImage(
    item: PhotoItem,
    tabType: UlFileRegisiterTabType
  ) {
    item.isSelected = !item.isSelected;
    if (item.isSelected) {
      this.selectedImageUrl = item.imagePath;
      if (tabType == UlFileRegisiterTabType.Photo) {
        this.selectedImageId = item.id;
      }
    } else {
      this.selectedImageUrl = "";
      this.selectedImageId = null;
    }
  }

  private resetPhotoSelectionState() {
    if (this.photos == null) {
      return;
    }
    this.photos.forEach(photo => {
      photo.photoItems.forEach(item => {
        item.isSelected = false;
      });
    });
  }

  private resetIconSelectionState() {
    if (this.icons != null && this.icons.length > 0) {
      this.icons.forEach(icon => {
        icon.icon.forEach(item => {
          item.isSelected = false;
        });
      });
    }
  }

  private resetFormatSelectionState() {
    if (this.formats != null && this.formats.length > 0) {
      this.formats.forEach(format => {
        format.formatItems.forEach(item => {
          item.isSelected = false;
        });
      });
    }
  }
}

import { Component, Mixins, Prop } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import { UlFileRegisiterTabType } from "@/utils/enums";
import { GetRequest, RegisterRequest } from "@/api/color-information/request";
import ColorInformationGet from "@/store/color-infomation/get";
import ColorInformationRegister from "@/store/color-infomation/register";
import Flash, {ErrorAlert} from "@/store/common/flash";
import WindowOpen from "@/utils/window-open";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest as GetAgreementRequest} from "@/api/shop-agreement/request";

@Component({ components: { UIFormRow, UlFileRegister } })
export default class NewEditLayout extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;

  @Prop({ type: Object, required: false })
  agreements: {} | undefined;

  @Prop({ type: Function, required: false })
  isNotAgreement: Function | undefined;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // file register
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  // color picker
  bgColorMode = "hexa" as "rgba" | "hexa" | "hsla";
  themeColorMode = "hexa" as "rgba" | "hexa" | "hsla";

  // 契約状態（基本情報）
  agreementsColor = {
    ap: false,
    line: false,
    web: false
  };

    // ショップロゴPC推奨サイズ
  ShopLogoImageSizePc = {
    width: 236,
    height: 100
  }

  // ショップロゴアプリ推奨サイズ
  ShopLogoImageSizeApp = {
    width: 118,
    height: 50
  }

  ShopLogoImageFileSizePc = 50;
  ShopLogoImageFileSizeApp = 50;

  ShopLogoPixelSizeDescription = null as string | null;
  ShopLogoFileSizeDescription = null as string | null;

  inputParams = {
    /** デザインID */
    designsId: 0 as number,
    /** ショップロゴ画像の編集フラグ */
    topShopLogoEditFlg: 0 as 0 | 1,
    /** ショップロゴ画像 */
    topShopLogoPath: null as string | null,
    /** t_shop_image_histories.id */
    topShopLogoImageId: null as number | null,
    /** コピーライト */
    copyright: null as string | null,
    /** トップ背景画像の編集フラグ */
    topBackgroundImageEditFlg: 0 as 0 | 1,
    /** トップ背景画像 */
    topBackgroundImagePath: null as string | null,
    /** t_shop_image_histories.id */
    topBackgroundImageId: null as number | null,
    /** トップ背景色 */
    topBackgroundColor: null as string | null,
    /** テーマカラー */
    topThemeColor: "" as string,
    /** 書き込み権限 */
    writeFlg: 0 as number
  };

  initLogoImageUrl = null as string | null;
  initBgImageUrl = null as string | null;

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  get appName() {
    if (this.$route.query.appName) {
      return this.$route.query.appName;
    } else {
      return "";
    }
  }

  get agreementsType() {
    return this.$route.query.agreementsType ? this.$route.query.agreementsType : "";
  }

  fileDescription() {
    if (this.agreementsColor.web === true) {
      this.ShopLogoPixelSizeDescription = "画像の登録がない場合、自動的に店名が表示されます。<br>※推奨サイズ：<span class='red--text'>横幅" + this.ShopLogoImageSizePc.width + "px 縦幅" + this.ShopLogoImageSizePc.height + "px</span>（推奨サイズ以外の場合、PC画面での表示が崩れる場合があります。）";
      this.ShopLogoFileSizeDescription = "※容量：<span class='red--text'>" + this.ShopLogoImageFileSizePc + "KB</span>以下";
    } else {
      this.ShopLogoPixelSizeDescription = "画像の登録がない場合、自動的に店名が表示されます。<br>※推奨サイズ：<span class='red--text'>横幅" + this.ShopLogoImageSizeApp.width + "px 縦幅" + this.ShopLogoImageSizeApp.height + "px</span>（推奨サイズ以外の場合、アプリ画面での表示が崩れる場合があります。）";
      this.ShopLogoFileSizeDescription = "※容量：<span class='red--text'>" + this.ShopLogoImageFileSizeApp + "KB</span>以下";
    }
  }

  async created() {
    await ColorInformationGet.get({ id: Number(this.shopId) } as GetRequest);
    if (!ColorInformationGet.isSuccess) {
      await Flash.setErrorNow({
        message: ColorInformationGet.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.syncInputParams();
    // 契約情報を取得する
    const requestAgrement = {
      shopId: this.shopId
    } as GetAgreementRequest;
    await ShopAgreementAPI.get(requestAgrement);
    if (!ShopAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: "",
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      const AgreementItems = ShopAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 1) {
          this.agreementsColor.ap = true;
        } else if (item.agreementsType == 2) {
          this.agreementsColor.line = true;
        } else if (item.agreementsType == 3) {
          this.agreementsColor.web = true;
        }
      });
    }
    this.fileDescription();
  }

  async beforeDestroy() {
    await ColorInformationGet.clearResponse();
    await ColorInformationRegister.clearResponse();
  }

  setLogoFile(data: string, id: number | null) {
    this.inputParams.topShopLogoEditFlg = 1;
    this.inputParams.topShopLogoPath = data;
    this.inputParams.topShopLogoImageId = id;
  }

  setBgFile(data: string, id: number | null) {
    this.inputParams.topBackgroundImageEditFlg = 1;
    this.inputParams.topBackgroundImagePath = data;
    this.inputParams.topBackgroundImageId = id;
  }

  unsetLogoFile() {
    this.inputParams.topShopLogoEditFlg = 1;
    this.inputParams.topShopLogoPath = null;
    this.inputParams.topShopLogoImageId = null;
  }

  unsetBgFile() {
    this.inputParams.topBackgroundImageEditFlg = 1;
    this.inputParams.topBackgroundImagePath = null;
    this.inputParams.topBackgroundImageId = null;
  }

  goBack() {
    this.$router.back();
  }

  async onSave() {
    await this.callRegisterApiColor(0);
    if (ColorInformationRegister.isSuccess) {
      await this.redirectWithSuccessAlert(
        "カラー設定を保存しました。",
        "/appdesign"
      );
    }
  }

  async preview() {
    await this.callRegisterApiColor(1);
    if (ColorInformationRegister.isSuccess) {
      WindowOpen.preview(ColorInformationRegister.getPreviewUrl);
    }
  }

  async webPreview() {
    await this.callRegisterApiColor(2);
    if (ColorInformationRegister.isSuccess) {
      WindowOpen.webPreview(ColorInformationRegister.getPreviewUrl);
    }
  }

  private async callRegisterApiColor(isPreview: number) {
    await Flash.clear();
    await ColorInformationRegister.register(
      this.createRegisterRequestColor(isPreview)
    );
    if (!ColorInformationRegister.isSuccess) {
      await Flash.setErrorNow({
        message: ColorInformationRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  private syncInputParams() {
    this.inputParams = {
      ...this.inputParams,
      ...ColorInformationGet.getDetail
    };
    if (ColorInformationGet.getDetail.topBackgroundColor == null) {
      this.inputParams.topBackgroundColor = "#FF0000";
    }
    if (ColorInformationGet.getDetail.topThemeColor == null) {
      this.inputParams.topThemeColor = "#FF0000";
    }
    this.initLogoImageUrl = this.inputParams.topShopLogoPath;
    this.initBgImageUrl = this.inputParams.topBackgroundImagePath;
  }

  private createRegisterRequestColor(isPreview: number) {
    const request = {
      id: Number(this.shopId),
      designsId: this.inputParams.designsId,
      topShopLogoEditFlg: this.inputParams.topShopLogoEditFlg,
      topShopLogoPath: this.inputParams.topShopLogoPath,
      topShopLogoImageId: this.inputParams.topShopLogoImageId,
      copyright: this.inputParams.copyright,
      topBackgroundImageEditFlg: this.inputParams.topBackgroundImageEditFlg,
      topBackgroundImagePath: this.inputParams.topBackgroundImagePath,
      topBackgroundImageId: this.inputParams.topBackgroundImageId,
      topBackgroundColor: this.inputParams.topBackgroundColor,
      topThemeColor: this.inputParams.topThemeColor,
      isPreview: isPreview
    } as RegisterRequest;
    //NEW_DEV-2220 cyber start
    if (
      request.topShopLogoEditFlg === 0 ||
      request.topShopLogoImageId != null
    ) {
      if(isPreview == 1 || isPreview == 2){
        request.topShopLogoEditFlg = 1;
      } else {
        delete request.topShopLogoPath;
      }
    }
    if (
      request.topBackgroundImageEditFlg === 0 ||
      request.topBackgroundImageId != null
    ) {
      if(isPreview == 1 || isPreview == 2){
        request.topBackgroundImageEditFlg = 1;
      } else {
        delete request.topBackgroundImagePath;
      }
    }
    //NEW_DEV-2220 cyber end
    return request;
  }
}

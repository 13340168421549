import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/app-layout/response";
import * as AppLayoutAPI from "@/api/app-layout";
import { isSuccess } from "@/api/response";
import { GetRequestLayout } from "@/api/app-layout/request";

const MODULE_NAME = "app-layout/get";

/**
 * アプリレイアウト情報取得API（/get-app-layout）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getResults() {
    return this.getResponse.results;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequestLayout) {
    const getResponse = await AppLayoutAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);

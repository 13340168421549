import { Component, Mixins, Prop } from "vue-property-decorator";
import UIDataTable from "@/components/UIDataTable.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegistersvg.vue";
import { UlFileRegisiterTabType } from "@/utils/enums";
import { TableOptions } from "@/api/request";
import {
  DetailRequest,
  GetRequest,
  RegisterRequest
} from "@/api/menu-information/request";
import { GetMenu } from "@/api/menu-information/response";
import MenuInformationGet from "@/store/menu-information/get";
import MenuInformationDetail from "@/store/menu-information/detail";
import MenuInformationRegister from "@/store/menu-information/register";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import Flash, { SuccessAlert } from "@/store/common/flash";
import WindowOpen from "@/utils/window-open";
import BannerRegister from "@/store/banner/register";

@Component({
  components: {
    UIDataTable,
    UIFormRow,
    UlFileRegister
  }
})
export default class NewEditMenu extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;

  @Prop({ type: Object, required: false })
  agreements: {} | undefined;

  @Prop({ type: Function, required: false })
  isNotAgreement: Function | undefined;

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "アイコン", value: "custom", sortable: false },
    { text: "機能名", value: "name", sortable: false },
    { text: "表示名", value: "displayName", sortable: false },
    { text: "利用設定", value: "menuViewType", sortable: false },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  // file register
  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/svg+xml"];
  tabType = UlFileRegisiterTabType.Icon;

  // ローディングステータス
  isLoading = false;

  isIndex = true;
  isEdit = false;

  // 利用設定を表示するか否か
  showEnableSetting = true;
  menuId = null as number | null;

  inputParams = {
    /** 表示名 */
    displayName: "" as string,
    /** 利用設定 */
    menuViewType: 0 as number,
    /** 画像編集フラグ */
    imageEditFlg: 0 as 0 | 1,
    /** メニュー画像 */
    imagePath: "" as string | null,
    /** t_shop_image_histories.id */
    imageId: null as number | null,
    /** 追加・編集権限の有無 */
    writeFlg: 0 as number
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  get appName() {
    if (this.$route.query.appName) {
      return this.$route.query.appName;
    } else {
      return "";
    }
  }

  get agreementsType() {
    return this.$route.query.agreementsType ? this.$route.query.agreementsType : "";
  }

  get title() {
    return this.showEnableSetting ? "メニュー編集" : "トップメニュー編集";
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return MenuInformationGet.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return 3;
  }

  async created() {
    await this.fetchMenuInformation();
  }

  async beforeDestroy() {
    await MenuInformationGet.clearResponse();
    await MenuInformationDetail.clearResponse();
    await MenuInformationRegister.clearResponse();
  }

  setFile(data: string, id: number | null) {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.imagePath = data;
    this.inputParams.imageId = id;
  }

  unsetFile() {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.imagePath = null;
    this.inputParams.imageId = null;
  }

  goBack() {
    this.isIndex = true;
    this.isEdit = false;
    window.scrollTo(0, 0);
    this.$router.back();
  }

  //20201105 外部リンク追加対応 cyber 段 start
  goBackForMenu() {
    this.isIndex = true;
    this.isEdit = false;
    window.scrollTo(0, 0);
  }
  //20201105 外部リンク追加対応 cyber 段 end

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {}

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async actionClickCallback(item: {}) {}

  /**
   * テーブル行の修正ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async editClickCallback(item: GetMenu) {
    await this.fetchMenuDetail(item.id);
    this.syncInputParams();
    this.isIndex = false;
    this.isEdit = true;
    this.initImageUrl = this.inputParams.imagePath;
    this.menuId = item.id;
    switch (item.name) {
      case "HOME":
      case "MENU":
      case "利用規約":
      case "マイページ":
      case "利用設定":
      case "アプリを友達に教える":
      case "特定商取引に基づく表記":
        // 特定のメニューは利用設定を非表示にする
        this.showEnableSetting = false;
        break;
      default:
        this.showEnableSetting = true;
    }
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: {}) {}

  async fetchMenuDetail(id: number) {
    this.isLoading = true;
    await MenuInformationDetail.detail({
      id: id,
      shopId: Number(this.shopId)
    } as DetailRequest);
    if (!MenuInformationDetail.isSuccess) {
      await Flash.setErrorNow({
        message: MenuInformationDetail.getMessage,
        showReloadButton: true
      });
    }
    this.isLoading = false;
  }

  async preview() {
    await this.callRegisterApi(1);
    if (MenuInformationRegister.isSuccess) {
      WindowOpen.preview(MenuInformationRegister.getPreviewUrl);
    }
  }

  /**
   * Webプレビュー
   */
  async webPreview() {
    await this.callRegisterApi(2);
    if (MenuInformationRegister.isSuccess) {
      WindowOpen.webPreview(MenuInformationRegister.getPreviewUrl);
    }
  }

  async onSave() {
    await this.callRegisterApi(0);
    if (MenuInformationRegister.isSuccess) {
      this.isIndex = true;
      this.isEdit = false;
      await Flash.setSuccessNow({
        message: "メニュー設定を保存しました。"
      } as SuccessAlert);
      await this.fetchMenuInformation();
    }
  }

  async fetchMenuInformation() {
    await MenuInformationGet.get({ id: Number(this.shopId) } as GetRequest);
    if (!MenuInformationGet.isSuccess) {
      await Flash.setErrorNow({
        message: MenuInformationGet.getMessage,
        showReloadButton: true
      });
      return;
    }
  }

  private async callRegisterApi(isPreview: number) {
    await Flash.clear();
    await MenuInformationRegister.register(
      this.createRegisterRequest(isPreview)
    );
    if (!MenuInformationRegister.isSuccess) {
      await Flash.setErrorNow({
        message: MenuInformationRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  private syncInputParams() {
    this.inputParams = {
      ...this.inputParams,
      ...MenuInformationDetail.getDetail
    };
  }

  private createRegisterRequest(isPreview: number) {
    const request = {
      id: Number(this.shopId),
      menuId: this.menuId,
      menuViewStatus: Number(this.inputParams.menuViewType),
      name: this.inputParams.displayName,
      imageEditFlg: this.inputParams.imageEditFlg,
      imagePath: this.inputParams.imagePath,
      imageId: this.inputParams.imageId,
      isPreview: isPreview
    } as RegisterRequest;
    if (!request.menuId) {
      delete request.menuId;
    }
    if (request.imageEditFlg === 0 || request.imageId != null) {
      delete request.imagePath;
    }
    return request;
  }
}

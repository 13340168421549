import service from "@/api/service";
import { GetResponse } from "@/api/image-get/response";
import { GetRequest } from "@/api/image-get/request";
import { CommonResponse } from "@/api/response";

/**
 * 画像ダイアログ情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(request: GetRequest) {
  const response = await service.post("/image-get", request);
  return response.data as GetResponse;
}

import service from "@/api/service";
import { GetRequest, SortRequest } from "@/api/sub-menu/request";
import { GetResponse } from "@/api/sub-menu/response";
import { CommonResponse } from "@/api/response";

/**
 * サブメニュー一覧APIをコールします。
 *
 * @param getRequest サブメニュー検索のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-sub-menu", getRequest);
  return response.data as GetResponse;
}

/**
 * サブメニュー並び替え登録更新APIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/sort-sub-menu", sortRequest);
  return response.data as CommonResponse;
}

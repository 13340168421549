import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as BannerAPI from "@/api/banner";
import { CommonResponse, isSuccess } from "@/api/response";
import { DeleteRequest, SortRequest } from "@/api/banner/request";

const MODULE_NAME = "banner/modify";

/**
 * バナー情報削除/登録更新APIのレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Modify extends VuexModule {
  // state
  commonResponse: CommonResponse = {} as CommonResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.commonResponse);
  }

  get getMessage() {
    return this.commonResponse.message;
  }

  // MutationActions
  @MutationAction
  async deleteOne(deleteRequest: DeleteRequest) {
    const commonResponse = await BannerAPI.deleteOne(deleteRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async sort(sortRequest: SortRequest) {
    const commonResponse = await BannerAPI.sort(sortRequest);
    return {
      commonResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      commonResponse: {} as CommonResponse
    };
  }
}

export default getModule(Modify);

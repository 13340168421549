import { Component, Prop, Vue } from "vue-property-decorator";
import UIDataTable from "@/components/UIDataTable.vue";
import { TableOptions } from "@/api/request";
import {
  GetRequest as GetFooterRequest,
  SortRequest as SortFooterRequest
} from "@/api/global-footer/request";
import {
  GetRequest as GetMenuRequest,
  SortRequest as SortMenuRequest
} from "@/api/sub-menu/request";
import GlobalFooterGet from "@/store/global-footer/get";
import GlobalFooterModify from "@/store/global-footer/modify";
import SubMenuGet from "@/store/sub-menu/get";
import SubMenuModify from "@/store/sub-menu/modify";
import Sortable, { MoveEvent } from "sortablejs";
import Flash, { ErrorAlert } from "@/store/common/flash";

@Component({
  components: {
    UIDataTable
  }
})
export default class NewEditOrder extends Vue {
  @Prop({ type: String, required: true })
  shopId!: string;

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "", value: "reorder", sortable: false },
    { text: "アイコン", value: "custom", sortable: false },
    { text: "機能名", value: "name", sortable: false }
  ];

  // ローディングステータス
  isLoading = false;

  activeTab = 0 as number;

  isInitHooterTable = false;
  isInitMenuTable = false;

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  get appName() {
    if (this.$route.query.appName) {
      return this.$route.query.appName;
    } else {
      return "";
    }
  }

  get agreementsType() {
    return this.$route.query.agreementsType ? this.$route.query.agreementsType : "";
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get footerTableItems() {
    return GlobalFooterGet.getItems;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get menuTableItems() {
    return SubMenuGet.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get footerTotalCount() {
    return GlobalFooterGet.getItems.length;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get menuTotalCount() {
    return SubMenuGet.getItems.length;
  }

  async created() {
    let getErrorMessage = null as string | null;
    this.isLoading = true;
    const footer = GlobalFooterGet.get({
      id: Number(this.shopId)
    } as GetFooterRequest);
    const menu = SubMenuGet.get({ id: Number(this.shopId) } as GetMenuRequest);
    await Promise.all([footer, menu]);
    if (!GlobalFooterGet.isSuccess) {
      getErrorMessage = GlobalFooterGet.getMessage;
    }
    if (!SubMenuGet.isSuccess) {
      getErrorMessage = SubMenuGet.getMessage;
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      });
    }
    this.isLoading = false;
  }

  mounted() {
    this.$nextTick(() => {
      this.initFooterTable();
    });
  }

  async beforeDestroy() {
    await GlobalFooterGet.clearResponse();
    await GlobalFooterModify.clearResponse();
    await SubMenuGet.clearResponse();
    await SubMenuModify.clearResponse();
  }

  goBack() {
    this.$router.back();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {}

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async actionClickCallback(item: {}) {}

  /**
   * テーブル行の修正ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async editClickCallback(item: {}) {}

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  deleteClickCallback(item: {}) {}

  tabChangeCallback(val: number) {
    // $nextTickだとDOMが生成されていないので止むを得ず
    setTimeout(() => {
      switch (val) {
        case 0:
          this.initFooterTable();
          break;
        case 1:
          this.initMenuTable();
          break;
      }
    }, 200);
  }

  private initFooterTable() {
    if (this.isInitHooterTable) {
      return;
    }
    const _self = this;

    const footerTable = document.querySelector(
      ".v-data-table.footer tbody"
    ) as HTMLElement;
    Sortable.create(footerTable, {
      onEnd({ newIndex, oldIndex }) {
        const rowSelected = _self.footerTableItems.splice(
          oldIndex as number,
          1
        )[0];
        _self.footerTableItems.splice(newIndex as number, 0, rowSelected);
        _self.sortGlobalFooter().then();
      },
      onMove(evt: MoveEvent, originalEvent: Event): boolean {
        return (
          !evt.dragged.querySelector(".not-sortable") &&
          !evt.related.querySelector(".not-sortable")
        );
      }
    });
    this.isInitHooterTable = true;
  }

  private initMenuTable() {
    if (this.isInitMenuTable) {
      return;
    }
    const _self = this;

    const menuTable = document.querySelector(
      ".v-data-table.menu tbody"
    ) as HTMLElement;
    Sortable.create(menuTable, {
      onEnd({ newIndex, oldIndex }) {
        const rowSelected = _self.menuTableItems.splice(
          oldIndex as number,
          1
        )[0];
        _self.menuTableItems.splice(newIndex as number, 0, rowSelected);
        _self.sortSubMenu().then();
      },
      onMove(evt: MoveEvent, originalEvent: Event): boolean {
        return (
          !evt.dragged.querySelector(".not-sortable") &&
          !evt.related.querySelector(".not-sortable")
        );
      }
    });
    this.isInitMenuTable = true;
  }

  /**
   * 並び替え処理
   */
  async sortGlobalFooter() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await GlobalFooterModify.sort(this.createGlobalFooterSortRequest());
    if (!GlobalFooterModify.isSuccess) {
      await Flash.setErrorNow({
        message: GlobalFooterModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.menu = SubMenuGet.get({ id: Number(this.shopId) } as GetMenuRequest);
    this.isLoading = false;
  }

  /**
   * 並び替え処理
   */
  async sortSubMenu() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await SubMenuModify.sort(this.createSubMenuSortRequest());
    if (!SubMenuModify.isSuccess) {
      await Flash.setErrorNow({
        message: SubMenuModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createGlobalFooterSortRequest() {
    const request = { shopId: Number(this.shopId) } as SortFooterRequest;
    request.ids = this.footerTableItems.map(i => i.id);
    return request;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createSubMenuSortRequest() {
    const request = { shopId: Number(this.shopId) } as SortMenuRequest;
    request.ids = this.menuTableItems.map(i => i.id);
    return request;
  }
}

import service from "@/api/service";
import { GetRequest, SortRequest } from "@/api/global-footer/request";
import { GetResponse } from "@/api/global-footer/response";
import { CommonResponse } from "@/api/response";

/**
 * グローバルフッター一覧APIをコールします。
 *
 * @param getRequest グローバルフッター検索のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-global-footer", getRequest);
  return response.data as GetResponse;
}

/**
 * グローバルフッター並び替え登録更新APIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/sort-global-footer", sortRequest);
  return response.data as CommonResponse;
}

export enum UlFileRegisiterTabType {
  Photo = "photo",
  Icon = "icon",
  Format = "format"
}

export const enum UIModalSpecialDayModalType {
  All,
  Single,
  Point
}

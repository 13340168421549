import service from "@/api/service";
import { GetRequest, RegisterRequest } from "@/api/color-information/request";
import {
  GetResponse,
  RegisterResponse
} from "@/api/color-information/response";

/**
 * アプリレイアウトカラー設定情報取得APIをコールします。
 *
 * @param getRequest アプリレイアウトカラー設定情報取得のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-colorInfomation", getRequest);
  return response.data as GetResponse;
}

/**
 * アプリレイアウトカラー設定情報登録更新APIをコールします。
 *
 * @param registerRequest アプリレイアウトカラー設定情報登録更新のリクエストボディ
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post(
    "/regist-colorInfomation",
    registerRequest
  );
  return response.data as RegisterResponse;
}

import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import {
  GetResponse,
  GetCassetteA,
  GetCassetteBc
} from "@/api/cassette/response";
import { GetRequest } from "@/api/cassette/request";
import * as MenuAPI from "@/api/cassette";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "cassette/get";

/**
 * カセットAのデコレーター
 */
export class ListItemA {
  constructor(private _item: GetCassetteA) {}
  get id() {
    return this._item.id;
  }
  get imagePath() {
    return this._item.imagePath;
  }
  get title() {
    return this._item.title;
  }
  get subTitle() {
    return this._item.subTitle;
  }
  get moreButton() {
    return this._item.moreButton;
  }
  get viewType() {
    return this._item.viewType;
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  get deleteFlg() {
    return this._item.deleteFlg;
  }
}

/**
 * カセットAのデコレーター
 */
export class ListItemBc {
  constructor(private _item: GetCassetteBc) {}
  get id() {
    return this._item.id;
  }
  get imagePath() {
    return this._item.imagePath;
  }
  get name() {
    return this._item.name;
  }
  get description() {
    return this._item.description;
  }
  get displayName() {
    return this._item.displayName;
  }
  get usageStatus() {
    return this._item.usageStatus;
  }
  get viewType() {
    return this._item.viewType;
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  //20201105 外部リンク追加対応 cyber 段 start
  get menuId() { 
    return this._item.menuId;
  }
  get externalLink(){
    return this._item.externalLink
  }
  get deleteFlg(){
    return this._item.deleteFlg
  }
  get shopMenuId(){
    return this._item.shopMenuId
  }
  //20201105 外部リンク追加対応 cyber 段 end
}

/**
 * カセット一覧・検索API（/get-cassette）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  searchResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getWriteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.writeFlg;
    } else {
      return false;
    }
  }

  get getTopType() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.topType;
    } else {
      return 0;
    }
  }

  get getCassetteA() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.cassetteA || []).map(
        value => new ListItemA(value)
      );
    } else {
      return [] as ListItemA[];
    }
  }

  get getCassetteBc() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.cassetteBc || []).map(
        value => new ListItemBc(value)
      );
    } else {
      return [] as ListItemBc[];
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const searchResponse = await MenuAPI.get(getRequest);
    return {
      searchResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);

import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { DetailResponse, DetailResult } from "@/api/menu-information/response";
import * as MenuAPI from "@/api/menu-information";
import { isSuccess } from "@/api/response";
import { DetailRequest } from "@/api/menu-information/request";

const MODULE_NAME = "menu-information/detail";

/**
 * メニュー詳細情報取得API（/get-menuInfomation-detail）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Detail extends VuexModule {
  // state
  detailResponse: DetailResponse = {} as DetailResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.detailResponse);
  }

  get getMessage() {
    return this.detailResponse.message;
  }

  get getDetail() {
    return this.detailResponse.results || ({} as DetailResult);
  }

  // MutationActions
  @MutationAction
  async detail(detailRequest: DetailRequest) {
    const detailResponse = await MenuAPI.detail(detailRequest);
    return {
      detailResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      detailResponse: {} as DetailResponse
    };
  }
}

export default getModule(Detail);

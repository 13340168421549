import { Component, Prop, Mixins } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import NewEditLayout from "@/views/app-design/NewEditLayout.vue";
import NewEditColor from "@/views/app-design/NewEditColor.vue";
import NewEditDetail from "@/views/app-design/NewEditDetail.vue";
import NewEditMenu from "@/views/app-design/NewEditMenu.vue";
import NewEditOrder from "@/views/app-design/NewEditOrder.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import AppLayoutGet from "@/store/app-layout/get";
import Flash, {ErrorAlert} from "@/store/common/flash";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import { GetRequest } from "@/api/shop-agreement/request";
import AppLayoutRegister from "@/store/app-layout/register";
import { GetRequestLayout, RegisterRequest } from "@/api/app-layout/request";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    NewEditLayout,
    NewEditColor,
    NewEditDetail,
    NewEditMenu,
    NewEditOrder
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  shopId!: string;

  // タイトル
  headingMain = "アプリデザイン";
  headingSub = "App design";

  // パンくず
  breadCrumbs = [
    { text: "アプリデザイン", disabled: true },
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "app-design" }
    },
    { text: "設定", disabled: true }
  ];

  tabItems = [
    {
      title: "レイアウト設定",
      component: "NewEditLayout",
      disabled: false,
      hidden: false
    },
    {
      title: "カラー設定",
      component: "NewEditColor",
      disabled: true,
      hidden: false
    },
    {
      title: "詳細設定",
      component: "NewEditDetail",
      disabled: true,
      hidden: false
    },
    {
      title: "メニュー設定",
      component: "NewEditMenu",
      disabled: true,
      hidden: false
    },
    {
      title: "表示順設定",
      component: "NewEditOrder",
      disabled: true,
      hidden: false
    }
  ];

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  /**
   * クエリパラメータから店舗名を返却する（必須）
   */
  get shopName() {
    if (this.$route.query.shopName) {
      return this.$route.query.shopName;
    } else {
      return "";
    }
  }

  get activeTab() {
    return this.$route.query.tab ? Number(this.$route.query.tab) : 0;
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  /**
   * 契約が Web のみか否かの判定
   * @return true:契約が Web のみ false:契約が Web のみでない
   */
  isWebAgreementOnly(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === true;
    return result;
  }

  /**
   * 契約が LINEミニアプリ のみか否かの判定
   * @return true:契約が LINEミニアプリ のみ false:契約が LINEミニアプリ のみでない
   */
  isLineAgreementOnly(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === true && this.agreements.web === false;
    return result;
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === false;
    return result;
  }

  /**
   * tabItems の内容を再構築する
   */
  rebuildTabItems() {
    if (this.agreements.ap == false && this.isNotAgreement() == false) {
      this.tabItems.forEach((value, index) => {
        this.tabItems[index].disabled = false;
        if (this.isLineAgreementOnly() == true) {
          // LINEミニアプリのみを契約している場合はレイアウト設定、メニュー設定、表示順設定を非表示
          if (index === 0 || index === 3 || index === 4) {
            this.tabItems[index].hidden = true;
          }
        } else {
          // ネイティブアプリを契約していない場合はレイアウト設定、表示順設定を非表示
          if (index === 0 || index === 4) {
            this.tabItems[index].hidden = true;
          }
        }
        let query = { ...this.$route.query };
        query.tab = String(1); //カラー設定を選択状態にする
        this.$router.replace({ query: query }).then();
      });
    } else {
      this.tabItems.forEach((value, index) => {
        this.tabItems[index].disabled = false;
      });
    }
  }

  async created() {
    const request = { id: Number(this.shopId) } as GetRequestLayout;
    await AppLayoutGet.get(request);
    if (!AppLayoutGet.isSuccess) {
      await Flash.setErrorNow({
        message: AppLayoutGet.getMessage,
        showReloadButton: true
      });
      return;
    }
    // 契約情報を取得する
    const requestAgreement = {
      shopId: this.shopId
    } as GetRequest;
    await ShopAgreementAPI.get(requestAgreement);
    if (!ShopAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: "",
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      let AgreementItems = ShopAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 1) {
          this.agreements.ap = true;
        } else if (item.agreementsType == 2) {
          this.agreements.line = true;
        } else if (item.agreementsType == 3) {
          this.agreements.web = true;
        }
      });
      // ネイティブアプリを契約していない、かつレイアウトが未登録の場合、レイアウトを登録する
      if (
        this.agreements.ap == false && AppLayoutGet.getResults.topType == null
      ) {
        await this.callRegisterApi(0);
        await AppLayoutGet.get(request);
        if (!AppLayoutGet.isSuccess) {
          await Flash.setErrorNow({
            message: AppLayoutGet.getMessage,
            showReloadButton: true
          });
          return;
        }
      }
      // タブを再構築
      this.rebuildTabItems();
    }
    if (AppLayoutGet.getResults.topType == null) {
      this.tabItems.forEach(value => (value.disabled = true));
    }
  }

  private async callRegisterApi(isPreview: number) {
    await Flash.clear();
    await AppLayoutRegister.register(this.createRegisterRequest(isPreview));
    if (!AppLayoutRegister.isSuccess) {
      await Flash.setErrorNow({
        message: AppLayoutRegister.getMessage,
        showReloadButton: false
      });
    }
  }

  private createRegisterRequest(isPreview: number) {
    return {
      id: Number(this.shopId),
      designsId: null,
      topType: 0,
      isPreview: isPreview
    } as RegisterRequest;
  }
}

import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  ConversionResponse,
  ConversionResult
} from "@/api/image-conversion/response";
import * as ImageAPI from "@/api/image-conversion";
import { isSuccess } from "@/api/response";
import { ConversionRequest } from "@/api/image-conversion/request";

const MODULE_NAME = "image/conversion";

/**
 * 画像フォーマット変換API（/image-conversion）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Conversion extends VuexModule {
  // state
  conversionResponse: ConversionResponse = {} as ConversionResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.conversionResponse);
  }

  get getMessage() {
    return this.conversionResponse.message;
  }

  get getBase64EncodedString() {
    if (this.conversionResponse.results) {
      return this.conversionResponse.results.setUrl;
    } else {
      return "";
    }
  }

  // MutationActions
  @MutationAction
  async conversion(request: ConversionRequest) {
    const conversionResponse = await ImageAPI.conversion(request);
    return {
      conversionResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      conversionResponse: {} as ConversionResponse
    };
  }
}

export default getModule(Conversion);

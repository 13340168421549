import service from "@/api/service";
import {
  GetRequest,
  DetailRequest,
  RegisterRequest
} from "@/api/menu-information/request";
import {
  GetResponse,
  DetailResponse,
  RegisterResponse
} from "@/api/menu-information/response";

/**
 * メニュー一覧APIをコールします。
 *
 * @param getRequest バナー検索のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-menuInfomation", getRequest);
  return response.data as GetResponse;
}

/**
 * メニュー詳細情報取得APIをコールします。
 *
 * @param detailRequest バナー詳細情報取得のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post(
    "/get-menuInfomation-detail",
    detailRequest
  );
  return response.data as DetailResponse;
}

/**
 * メニュー情報登録更新APIをコールします。
 *
 * @param registerRequest バナー情報登録更新のリクエストボディ
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post(
    "/regist-menuInfomation",
    registerRequest
  );
  return response.data as RegisterResponse;
}

import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse, GetBanner } from "@/api/banner/response";
import { GetRequest } from "@/api/banner/request";
import * as MenuAPI from "@/api/banner";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "banner/get";

/**
 * バナーのデコレーター
 */
export class ListItem {
  constructor(private _item: GetBanner) {}
  get id() {
    return this._item.id;
  }
  get imagePath() {
    return this._item.imagePath;
  }
  get linkType() {
    return this._item.linkType == 1 ? "利用する" : "利用しない";
  }
  get title() {
    return this._item.title;
  }
  get date() {
    return this._item.date;
  }
  get viewType() {
    return this._item.viewType;
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
  get deleteFlg() {
    return this._item.deleteFlg;
  }
}

/**
 * バナー一覧・検索API（/get-banner）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  searchResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getWriteFlg() {
    if (this.searchResponse.results) {
      return this.searchResponse.results.writeFlg;
    } else {
      return false;
    }
  }

  get getItems() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.banner || []).map(
        value => new ListItem(value)
      );
    } else {
      return [] as ListItem[];
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const searchResponse = await MenuAPI.get(getRequest);
    return {
      searchResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);

import service from "@/api/service";
import { GetResponse, RegisterResponse } from "@/api/app-layout/response";
import { GetRequestLayout, RegisterRequest } from "@/api/app-layout/request";

/**
 * アプリレイアウト情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get(getRequest: GetRequestLayout) {
  const response = await service.post("/get-app-layout", getRequest);
  return response.data as GetResponse;
}

/**
 * アプリレイアウト情報登録更新APIをコールします。
 *
 * @param registerRequest アプリレイアウト情報登録更新のリクエストボディ
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-app-layout", registerRequest);
  return response.data as RegisterResponse;
}

import { Component, Prop, Vue } from "vue-property-decorator";
import UIDataTable from "@/components/UIDataTable.vue";
import UIDialogDelete from "@/components/UlDialogDelete.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import UIDatePicker from "@/components/UIDatePicker.vue";
import { UlFileRegisiterTabType } from "@/utils/enums";
import { TableOptions } from "@/api/request";
import {
  DeleteRequest as DeleteCassetteRequest,
  DetailRequest as DetailCassetteRequest,
  GetRequest as GetCassetteRequest,
  RegisterRequest as RegisterCassetteRequest,
  SortRequest as SortCassetteRequest
} from "@/api/cassette/request";
import {
  DeleteRequest as DeleteBannerRequest,
  DetailRequest as DetailBannerRequest,
  GetRequest as GetBannerRequest,
  RegisterRequest as RegisterBannerRequest,
  SortRequest as SortBannerRequest
} from "@/api/banner/request";
import { GetRequest as GetTransitionRequest } from "@/api/app-transition/request";
import BannerGet, { ListItem as BannerItem } from "@/store/banner/get";
import CassetteGet, {
  ListItemA as CassetteAItem,
  ListItemBc as CassetteBcItem
} from "@/store/cassette/get";
import CassetteModify from "@/store/cassette/modify";
import CassetteDetail from "@/store/cassette/detail";
import CassetteRegister from "@/store/cassette/register";
import BannerModify from "@/store/banner/modify";
import BannerDetail from "@/store/banner/detail";
import BannerRegister from "@/store/banner/register";
import AppTransitionGet from "@/store/app-transition/get";
import Sortable, { MoveEvent } from "sortablejs";
import Flash, { ErrorAlert, SuccessAlert } from "@/store/common/flash";
import _ from "lodash";
import WindowOpen from "@/utils/window-open";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest as GetAgreementRequest} from "@/api/shop-agreement/request";

const DEFAULT_BANNER_INPUT_PARAMS = {
  viewFlg: 0 as number,
  title: "" as string,
  publishDateFrom: "" as string,
  publishDateTo: "" as string,
  imageEditFlg: 0 as 0 | 1,
  imagePath: null as string | null,
  imageId: null as number | null,
  externalLinkFlg: 0 as number,
  externalUrl: null as string | null,
  startBrowserFlg: 0 as number,
  writeFlg: 1 as number
};

const DEFAULT_CASSETTE_INPUT_PARAMS = {
  topType: 0 as number,
  writeFlg: 1 as number,
  cassetteA: {
    viewType: 0,
    imageEditFlg: 0 as 0 | 1,
    imagePath: "" as null | string,
    imageId: null as number | null,
    titleViewType: 0,
    title: null,
    titleColor: "#FF0000",
    subTitle: null,
    subTitleColor: "#FF0000",
    moreViewFlg: 0,
    moreColor: "#FF0000",
    transitionType: 0,
    transitionInfo: "",
    startBrowserFlg: 0
  },
  cassetteBc: {
    viewType: 0,
    imageEditFlg: 0 as 0 | 1,
    imagePath: "" as null | string,
    imageId: null as number | null,
    //20201105 外部リンク追加対応 cyber 段 start
    titleViewType: 0,
    title: null,
    titleColor: "#FF0000",
    headline: null,
    headlineColor: "#FF0000",
    buttonName: null,
    transitionInfo: "",
    transitionType: 1,
    startBrowserFlg: 0,
    shopMenuId: null as number | null,
    externalLinkId: null as number | null
    //20201105 外部リンク追加対応 cyber 段 end
  }
};

@Component({
  components: {
    UIDataTable,
    UIDialogDelete,
    UIFormRow,
    UlFileRegister,
    UIDatePicker
  }
})
export default class NewEditDetail extends Vue {
  @Prop({ type: String, required: true })
  shopId!: string;

  @Prop({ type: Object, required: false })
  agreements: {} | undefined;

  @Prop({ type: Function, required: false })
  isWebAgreementOnly: Function | undefined;

  @Prop({ type: Function, required: false })
  isLineAgreementOnly: Function | undefined;

  @Prop({ type: Function, required: false })
  isNotAgreement: Function | undefined;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  bannerTableHeaders = [
    { text: "", value: "reorder", sortable: false },
    { text: "画像", value: "custom", sortable: false },
    { text: "リンク先の指定", value: "linkType", sortable: false },
    { text: "バナータイトル", value: "title", sortable: false },
    { text: "掲載期間", value: "date", sortable: false },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    }
  ];

  cassetteATableHeaders = [
    { text: "", value: "reorder", sortable: false },
    { text: "画像", value: "custom", sortable: false },
    { text: "カセットタイトル", value: "title", sortable: false },
    { text: "サブタイトル", value: "subTitle", sortable: false },
    { text: "Moreボタン", value: "moreButton", sortable: false },
    { text: "表示", value: "viewType", sortable: false },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    },
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    }
  ];

  cassetteBcTableHeaders = [
    { text: "", value: "reorder", sortable: false },
    { text: "アイコン", value: "custom", sortable: false },
    { text: "機能名", value: "name", sortable: false },
    { text: "表示名", value: "displayName", sortable: false },
    { text: "利用", value: "usageStatus", sortable: false },
    { text: "表示", value: "viewType", sortable: false },
    {
      label: "編集",
      text: "",
      value: "edit",
      sortable: false
    },
    //20201105 外部リンク追加対応 cyber 段 start
    {
      label: "削除",
      text: "",
      value: "delete",
      sortable: false
    }
    //20201105 外部リンク追加対応 cyber 段 end
  ];

  showBannerDialog = false;
  showCassetteDialog = false;

  deletingBannerName = null as string | null;
  deletingCassetteName = null as string | null;

  deletingBannerId = null as number | null;
  deletingCassetteId = null as number | null;

  // file register
  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  // color picker
  titleColorMode = "hexa" as "rgba" | "hexa" | "hsla";
  subTitleColorMode = "hexa" as "rgba" | "hexa" | "hsla";
  buttonColorMode = "hexa" as "rgba" | "hexa" | "hsla";

  // ローディングステータス
  isLoading = false;

  showBannerTable = true;
  showCassetteATable = true;
  showCassetteBcTable = true;

  isLadder = true;
  //20201105 外部リンク追加対応 cyber 段 start
  isExternalLink = false;
  shopMenuId  = null as number | null;
  //20201105 外部リンク追加対応 cyber 段 end
  isIndex = true;
  isBannerNewEdit = false;
  isCassetteNewEdit = false;

  bannerInputParams = DEFAULT_BANNER_INPUT_PARAMS;

  newEditBannerId = null as number | null;

  cassetteInputParams = DEFAULT_CASSETTE_INPUT_PARAMS;

  newEditCassetteId = null as number | null;
  transitionMenuKey = "";
  transitionUrl = "";

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = {} as TableOptions;

  // 契約状態（詳細）
  agreementsDetail = {
    ap: false,
    line: false,
    web: false
  };

  // PC推奨サイズ
  imageSizePc = {
    width: 2880,
    height: 1252
  }

  // アプリ推奨サイズ
  imageSizeApp = {
    width: 1440,
    height: 626
  }

  imageFileSizePc = 500;
  imageFileSizeApp = 500;

  pixelSizeDescription = null as string | null;
  fileSizeDescription = null as string | null;

  get shopName() {
    return this.$route.query.shopName ? this.$route.query.shopName : "";
  }

  get appName() {
    if (this.$route.query.appName) {
      return this.$route.query.appName;
    } else {
      return "";
    }
  }

  get agreementsType() {
    return this.$route.query.agreementsType ? this.$route.query.agreementsType : "";
  }

  /**
   * カセット新規作成権限の有無
   */
  get canNewCassette() {
    return CassetteGet.getWriteFlg;
  }

  /**
   * バナー新規作成権限の有無
   */
  get canNewBanner() {
    if (this.bannerTotalCount > 4) {
      return false;
    }
    return BannerGet.getWriteFlg;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get bannerTableItems() {
    return BannerGet.getItems;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get cassetteATableItems() {
    return CassetteGet.getCassetteA;
  }

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get cassetteBcTableItems() {
    return CassetteGet.getCassetteBc;
  }

  get appTransitionItems() {
    return AppTransitionGet.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get bannerTotalCount() {
    return BannerGet.getItems.length;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get cassetteATotalCount() {
    return CassetteGet.getCassetteA.length;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get cassetteBcTotalCount() {
    return CassetteGet.getCassetteBc.length;
  }

  get cassetteViewType() {
    if (this.isLadder) {
      if (this.cassetteInputParams.cassetteA) {
        return this.cassetteInputParams.cassetteA.viewType;
      } else {
        return 0;
      }
    } else {
      if (this.cassetteInputParams.cassetteBc) {
        return this.cassetteInputParams.cassetteBc.viewType;
      } else {
        return 0;
      }
    }
  }

  set cassetteViewType(val) {
    if (this.isLadder) {
      if (this.cassetteInputParams.cassetteA) {
        this.cassetteInputParams.cassetteA.viewType = val;
      }
    } else {
      if (this.cassetteInputParams.cassetteBc) {
        this.cassetteInputParams.cassetteBc.viewType = val;
      }
    }
  }

  fileDescription() {
    if (this.agreementsDetail.web === true) {
      this.pixelSizeDescription = "推奨サイズ：<span class='red--text'>横幅" + this.imageSizePc.width + "px 縦幅" + this.imageSizePc.height + "px</span>（推奨サイズ以外の場合、アプリ画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "容量：<span class='red--text'>" + this.imageFileSizePc + "KB</span>以下";
    } else {
      this.pixelSizeDescription = "推奨サイズ：<span class='red--text'>横幅" + this.imageSizeApp.width + "px 縦幅" + this.imageSizeApp.height + "px</span>（推奨サイズ以外の場合、アプリ画面での表示が崩れる場合があります。）";
      this.fileSizeDescription = "容量：<span class='red--text'>" + this.imageFileSizeApp + "KB</span>以下";
    }
  }

  async created() {
    await this.fetchBannerList();
    if (!BannerGet.isSuccess) {
      return;
    }
    await this.fetchCassetteList();
    if (!CassetteGet.isSuccess) {
      return;
    }

    // 契約情報を取得する
    const requestAgrement = {
      shopId: this.shopId
    } as GetAgreementRequest;
    await ShopAgreementAPI.get(requestAgrement);
    if (!ShopAgreementAPI.isSuccess) {
      await Flash.setErrorNow({
        message: "",
        showReloadButton: true
      } as ErrorAlert);
      return;
    } else {
      const AgreementItems = ShopAgreementAPI.getagreementsList;
      AgreementItems.forEach((item: { agreementsType: number }) => {
        if (item.agreementsType == 1) {
          this.agreementsDetail.ap = true;
        } else if (item.agreementsType == 2) {
          this.agreementsDetail.line = true;
        } else if (item.agreementsType == 3) {
          this.agreementsDetail.web = true;
        }
      });
    }

    await this.fetchAppTransition();
    if (!AppTransitionGet.isSuccess) {
      return;
    }
    if (CassetteGet.getTopType === 0) {
      this.isLadder = true;
      this.showBannerTable = true;
      this.showCassetteATable = true;
      this.showCassetteBcTable = false;
    } else {
      this.isLadder = false;
      this.showBannerTable = false;
      this.showCassetteATable = false;
      this.showCassetteBcTable = true;
    }


    this.fileDescription();
  }

  mounted() {
    this.$nextTick(() => {
      const _self = this;

      const bannerTable = document.querySelector(
        ".v-data-table.banner tbody"
      ) as HTMLElement;
      Sortable.create(bannerTable, {
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = _self.bannerTableItems.splice(
            oldIndex as number,
            1
          )[0];
          _self.bannerTableItems.splice(newIndex as number, 0, rowSelected);
          _self.sortBanner().then();
        },
        onMove(evt: MoveEvent, originalEvent: Event): boolean {
          return (
            !evt.dragged.querySelector(".not-sortable") &&
            !evt.related.querySelector(".not-sortable")
          );
        }
      });

      const cassetteA = document.querySelector(
        ".v-data-table.cassette_a tbody"
      ) as HTMLElement;
      Sortable.create(cassetteA, {
        onEnd({ newIndex, oldIndex }) {
          const rowSelected = _self.cassetteATableItems.splice(
            oldIndex as number,
            1
          )[0];
          _self.cassetteATableItems.splice(newIndex as number, 0, rowSelected);
          _self.sortCassetteA().then();
        },
        onMove(evt: MoveEvent, originalEvent: Event): boolean {
          return (
            !evt.dragged.querySelector(".not-sortable") &&
            !evt.related.querySelector(".not-sortable")
          );
        }
      });

      const cassetteBc = document.querySelector(
        ".v-data-table.cassette_bc tbody"
      ) as HTMLElement;
      Sortable.create(cassetteBc, {
        onEnd({ newIndex, oldIndex }) {
          //20201105 外部リンク追加対応 cyber 段 start
          const rowSelected = _self.cassetteBcTableItems.splice(
            oldIndex as number,
            1
          )[0];
          _self.cassetteBcTableItems.splice(newIndex as number, 0, rowSelected);
          //20201105 外部リンク追加対応 cyber 段 end
          _self.sortCassetteBc().then();
        },
        onMove(evt: MoveEvent, originalEvent: Event): boolean {
          return (
            !evt.dragged.querySelector(".not-sortable") &&
            !evt.related.querySelector(".not-sortable")
          );
        }
      });
    });
  }

  async beforeDestroy() {
    await CassetteGet.clearResponse();
    await CassetteModify.clearResponse();
    await CassetteDetail.clearResponse();
    await CassetteRegister.clearResponse();
    await BannerGet.clearResponse();
    await BannerModify.clearResponse();
    await BannerDetail.clearResponse();
    await BannerRegister.clearResponse();
    await AppTransitionGet.clearResponse();
  }

  setBannerFile(data: string, id: number | null) {
    this.bannerInputParams.imageEditFlg = 1;
    this.bannerInputParams.imagePath = data;
    this.bannerInputParams.imageId = id;
  }

  setCassetteFile(data: string, id: number | null) {
    if (this.cassetteInputParams.cassetteA) {
      this.cassetteInputParams.cassetteA.imageEditFlg = 1;
      this.cassetteInputParams.cassetteA.imagePath = data;
      this.cassetteInputParams.cassetteA.imageId = id;
    }
  }

  unsetBannerFile() {
    this.bannerInputParams.imageEditFlg = 1;
    this.bannerInputParams.imagePath = null;
    this.bannerInputParams.imageId = null;
  }

  unsetCassetteFile() {
    if (this.cassetteInputParams.cassetteA) {
      this.cassetteInputParams.cassetteA.imageEditFlg = 1;
      this.cassetteInputParams.cassetteA.imagePath = null;
      this.cassetteInputParams.cassetteA.imageId = null;
    }
  }

  // 20201105 外部リンク追加対応 cyber 段 start
  setExternalLinkImageFile(data: string, id: number | null) {
    if (this.cassetteInputParams.cassetteBc) {
      this.cassetteInputParams.cassetteBc.imageEditFlg = 1;
      this.cassetteInputParams.cassetteBc.imagePath = data;
      this.cassetteInputParams.cassetteBc.imageId = id;
    }
  }

  unsetExternalLinkImageFile() {
    if (this.cassetteInputParams.cassetteBc) {
      this.cassetteInputParams.cassetteBc.imageEditFlg = 1;
      this.cassetteInputParams.cassetteBc.imagePath = null;
      this.cassetteInputParams.cassetteBc.imageId = null;
    }
  }

  goBackForCassette() {
    this.isIndex = true;
    this.isBannerNewEdit = false;
    this.isCassetteNewEdit = false;
    this.cassetteInputParams.cassetteA.title = null;
    this.cassetteInputParams.cassetteA.subTitle = null;
    this.cassetteInputParams.cassetteA.transitionInfo = "";
    window.scrollTo(0, 0);
  }
  // 20201105 外部リンク追加対応 cyber 段 end

  goBack() {
    this.isIndex = true;
    this.isBannerNewEdit = false;
    this.isCassetteNewEdit = false;
    window.scrollTo(0, 0);
  }

  goToBannerNew() {
    this.bannerInputParams = DEFAULT_BANNER_INPUT_PARAMS;
    this.isIndex = false;
    this.isBannerNewEdit = true;
    this.initImageUrl = null;
    this.newEditBannerId = null;
    window.scrollTo(0, 0);
  }

  goToCassetteNew() {
    this.cassetteInputParams = DEFAULT_CASSETTE_INPUT_PARAMS;
    //20201105 外部リンク追加対応 cyber 段 start
    if(CassetteGet.getTopType === 0){
      this.isExternalLink = false;
    }else{ // BCの場合外部リンクがある
      this.isExternalLink = true;
    }
    //20201105 外部リンク追加対応 cyber 段 end
    this.isIndex = false;
    this.isCassetteNewEdit = true;
    this.initImageUrl = null;
    this.newEditCassetteId = null;
    this.transitionMenuKey = "";
    this.transitionUrl = "";
    window.scrollTo(0, 0);
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {}

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async actionClickCallback(item: {}) {}

  /**
   * テーブル行の修正ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async bannerEditClickCallback(item: BannerItem) {
    await this.fetchBannerDetail(item.id);
    this.isIndex = false;
    this.isBannerNewEdit = true;
    this.initImageUrl = this.bannerInputParams.imagePath;
    this.newEditBannerId = item.id;
    window.scrollTo(0, 0);
  }

  /**
   * テーブル行の修正ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async cassetteAEditClickCallback(item: CassetteAItem) {
    await this.fetchCassetteDetail(item.id);
    this.isIndex = false;
    this.isCassetteNewEdit = true;
    const cassetteA = this.cassetteInputParams.cassetteA;
    this.initImageUrl = cassetteA.imagePath;
    this.newEditCassetteId = item.id;
    if (cassetteA.transitionType === 0) {
      this.transitionMenuKey = cassetteA.transitionInfo || "";
      this.transitionUrl = "";
    } else {
      this.transitionMenuKey = "";
      this.transitionUrl = cassetteA.transitionInfo || "";
    }
    window.scrollTo(0, 0);
  }

  /**
   * テーブル行の修正ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のSearchItem
   */
  async cassetteBcEditClickCallback(item: CassetteBcItem) {
    await this.fetchCassetteDetail(item.id);
    //20201105 外部リンク追加対応 cyber 段 start
    if (item.externalLink == 1) {
      this.isExternalLink = true;
    }else {
      this.isExternalLink = false;
    }
    this.isIndex = false;
    this.isCassetteNewEdit = true;
    const cassetteBc = this.cassetteInputParams.cassetteBc;
    this.initImageUrl = cassetteBc.imagePath;
    //20201105 外部リンク追加対応 cyber 段 end
    this.newEditCassetteId = item.id;
    window.scrollTo(0, 0);
  }

  /**
   * 掲載開始日が変更された際のコールバック
   *
   * @param date 選択された日付
   */
  changeBannerFromCallback(date: string | null) {
    if (date) {
      this.bannerInputParams.publishDateFrom = date;
    }
  }

  /**
   * 掲載終了日が変更された際のコールバック
   *
   * @param date 選択された日付
   */
  changeBannerToCallback(date: string | null) {
    if (date) {
      this.bannerInputParams.publishDateTo = date;
    }
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  bannerDeleteClickCallback(item: BannerItem) {
    this.showBannerDialog = true;
    this.deletingBannerId = item.id;
    this.deletingBannerName = item.title;
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  cassetteDeleteClickCallback(item: CassetteAItem) {
    this.showCassetteDialog = true;
    this.deletingCassetteId = item.id;
    this.deletingCassetteName = item.title;
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   * 20201105 外部リンク追加対応 cyber 段
   * @param item 選択行のAdminItem
   */
  cassetteBCLinkDeleteClickCallback(item: CassetteBcItem) {
    this.showCassetteDialog = true;
    this.deletingCassetteId = item.id;
    this.deletingCassetteName = item.displayName;
    this.shopMenuId = item.shopMenuId;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteBannerOnDialog() {
    await Flash.clear();
    this.showBannerDialog = false;
    if (this.deletingBannerId && this.deletingBannerId <= 0) {
      return;
    }

    this.isLoading = true;
    await BannerModify.deleteOne({
      id: this.deletingBannerId,
      shopId: Number(this.shopId)
    } as DeleteBannerRequest);
    if (BannerModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "バナーを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.fetchBannerList();
    } else {
      await Flash.setErrorNow({
        message: BannerModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * ダイアログの削除のコールバック
   */
  async doDeleteCassetteOnDialog() {
    await Flash.clear();
    this.showCassetteDialog = false;
    if (this.deletingCassetteId && this.deletingCassetteId <= 0) {
      return;
    }

    this.isLoading = true;
    await CassetteModify.deleteOne({
      id: this.deletingCassetteId,
      shopId: Number(this.shopId),
      //20201105 外部リンク追加対応 cyber 段 start
      topType: CassetteGet.getTopType,
      shopMenuId: this.shopMenuId
      //20201105 外部リンク追加対応 cyber 段 end
    } as DeleteCassetteRequest);
    if (CassetteModify.isSuccess) {
      await Flash.setSuccessNow({
        message: "カセットを削除しました。",
        consumePath: ""
      } as SuccessAlert);
      await this.fetchCassetteList();
    } else {
      await Flash.setErrorNow({
        message: CassetteModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * バナー情報の一覧を取得する処理
   */
  async fetchBannerList() {
    this.isLoading = true;
    await BannerGet.get({ id: Number(this.shopId) } as GetBannerRequest);
    if (!BannerGet.isSuccess) {
      await Flash.setErrorNow({
        message: BannerGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * カセット情報の一覧を取得する処理
   */
  async fetchCassetteList() {
    this.isLoading = true;
    await CassetteGet.get({ id: Number(this.shopId) } as GetCassetteRequest);
    if (!CassetteGet.isSuccess) {
      await Flash.setErrorNow({
        message: CassetteGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  async fetchBannerDetail(id: number) {
    this.isLoading = true;
    await BannerDetail.detail({
      id: id,
      shopId: Number(this.shopId)
    } as DetailBannerRequest);
    if (!BannerDetail.isSuccess) {
      await Flash.setErrorNow({
        message: BannerDetail.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
    this.syncBannerInputParams();
  }

  async fetchCassetteDetail(id: number) {
    this.isLoading = true;
    await CassetteDetail.detail({
      id: id,
      shopId: Number(this.shopId)
    } as DetailCassetteRequest);
    if (!CassetteDetail.isSuccess) {
      await Flash.setErrorNow({
        message: CassetteDetail.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
    this.syncCassetteInputParams();
  }

  async fetchAppTransition() {
    this.isLoading = true;
    await AppTransitionGet.get({
      id: Number(this.shopId),
      isWebAgreement: this.agreementsDetail.web
    } as GetTransitionRequest);
    if (!AppTransitionGet.isSuccess) {
      await Flash.setErrorNow({
        message: AppTransitionGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 並び替え処理
   */
  async sortBanner() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await BannerModify.sort(this.createBannerSortRequest());
    if (!BannerModify.isSuccess) {
      await Flash.setErrorNow({
        message: BannerModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 並び替え処理
   */
  async sortCassetteA() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await CassetteModify.sort(this.createCassetteASortRequest());
    if (!CassetteModify.isSuccess) {
      await Flash.setErrorNow({
        message: CassetteModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * 並び替え処理
   */
  async sortCassetteBc() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    await CassetteModify.sort(this.createCassetteBcSortRequest());
    if (!CassetteModify.isSuccess) {
      await Flash.setErrorNow({
        message: CassetteModify.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * APプレビュー
   */
  async previewBanner() {
    await this.callBannerRegisterApi(1);
    if (BannerRegister.isSuccess) {
      WindowOpen.preview(BannerRegister.getPreviewUrl);
    }
  }

  /**
   * Webプレビュー
   */
  async webPreview() {
    await this.callBannerRegisterApi(2);
    if (BannerRegister.isSuccess) {
      WindowOpen.webPreview(BannerRegister.getPreviewUrl);
    }
  }

  async onBannerSave() {
    await this.callBannerRegisterApi(0);
    if (BannerRegister.isSuccess) {
      this.isBannerNewEdit = false;
      this.isIndex = true;
      Flash.setSuccessNow({
        message: "バナー設定を保存しました。"
      } as SuccessAlert).then();
      await this.fetchBannerList();
    }
  }

  async previewCassette() {
    await this.callCassetteRegisterApi(1);
    if (CassetteRegister.isSuccess) {
      WindowOpen.preview(CassetteRegister.getPreviewUrl);
    }
  }

  async onCassetteSave() {
    await this.callCassetteRegisterApi(0);
    if (CassetteRegister.isSuccess) {
      this.isCassetteNewEdit = false;
      this.isIndex = true;
      Flash.setSuccessNow({
        message: "カセット設定を保存しました。"
      } as SuccessAlert).then();
      await this.fetchCassetteList();
    }
  }

  private async callBannerRegisterApi(isPreview: number) {
    await Flash.clear();
    await BannerRegister.register(this.createBannerRegisterRequest(isPreview));
    if (!BannerRegister.isSuccess) {
      await Flash.setErrorNow({
        message: BannerRegister.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  private async callCassetteRegisterApi(isPreview: number) {
    await Flash.clear();
    await CassetteRegister.register(
      this.createCassetteRegisterRequest(isPreview)
    );
    if (!CassetteRegister.isSuccess) {
      await Flash.setErrorNow({
        message: CassetteRegister.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  private syncBannerInputParams() {
    this.bannerInputParams = {
      ...this.bannerInputParams,
      ...BannerDetail.getDetail
    };
  }

  private syncCassetteInputParams() {
    this.cassetteInputParams = _.merge(
      this.cassetteInputParams,
      CassetteDetail.getDetail
    );
    if (this.cassetteInputParams.cassetteA) {
      if (this.cassetteInputParams.cassetteA.titleColor == null) {
        this.cassetteInputParams.cassetteA.titleColor = "#FF0000";
      }
      if (this.cassetteInputParams.cassetteA.subTitleColor == null) {
        this.cassetteInputParams.cassetteA.subTitleColor = "#FF0000";
      }
      if (this.cassetteInputParams.cassetteA.moreColor == null) {
        this.cassetteInputParams.cassetteA.moreColor = "#FF0000";
      }
    }
  }

  private createBannerRegisterRequest(isPreview: number) {
    const request = {
      ...this.bannerInputParams,
      ...{
        id: Number(this.shopId),
        bannerId: this.newEditBannerId,
        isPreview: isPreview
      }
    };
    if (request.imageEditFlg === 0 || request.imageId != null) {
      delete request.imagePath;
    }
    delete request.writeFlg;
    return request as RegisterBannerRequest;
  }

  private createCassetteRegisterRequest(isPreview: number) {
    const request = {
      id: Number(this.shopId),
      cassetteId: this.newEditCassetteId,
      topType: CassetteGet.getTopType,
      isPreview: isPreview
    } as RegisterCassetteRequest;
    if (this.isLadder) {
      const cassetteA = this.cassetteInputParams.cassetteA;
      request.viewFlg = cassetteA.viewType;
      // NEW_DEV-2286 cyber start
      if(cassetteA.imageEditFlg === 1) {
        request.backgroundImageEditFlg = cassetteA.imageEditFlg;
      } else {
        request.backgroundImageEditFlg = 0;
      }
      // NEW_DEV-2286 cyber end
      request.backgroundImage = cassetteA.imagePath;
      request.backgroundImageId = cassetteA.imageId;
      if (
        request.backgroundImageEditFlg === 0 ||
        request.backgroundImageId != null
      ) {
        delete request.backgroundImage;
      }
      request.titleViewFlg = cassetteA.titleViewType;
      request.title = cassetteA.title;
      request.moreViewFlg = cassetteA.moreViewFlg;
      request.transitionType = cassetteA.transitionType;
      request.transitionMenuKey = cassetteA.transitionInfo;
      request.transitionUrl = cassetteA.transitionInfo;
      request.startBrowserFlg = cassetteA.startBrowserFlg;

      if (cassetteA.titleColor) {
        request.titleColor = cassetteA.titleColor;
      } else {
        delete request.titleColor;
      }
      if (cassetteA.subTitle) {
        request.subTitle = cassetteA.subTitle;
      } else {
        delete request.subTitle;
      }
      if (cassetteA.subTitleColor) {
        request.subTitleColor = cassetteA.subTitleColor;
      } else {
        delete request.subTitleColor;
      }
      if (cassetteA.moreColor) {
        request.moreColor = cassetteA.moreColor;
      } else {
        delete request.moreColor;
      }
    } else {
      const cassetteBc = this.cassetteInputParams.cassetteBc;
      request.viewFlg = cassetteBc.viewType;
      request.moreViewFlg = 0;
      request.transitionType = 0;
      request.transitionMenuKey = "dummy";
      request.backgroundImageEditFlg = cassetteBc.imageEditFlg;
      request.backgroundImage = cassetteBc.imagePath;
      request.backgroundImageId = cassetteBc.imageId;

      //20201105 外部リンク追加対応 cyber 段 start
      if (this.isExternalLink) {
        request.titleViewFlg = cassetteBc.titleViewType;
        request.title = cassetteBc.title;
        request.transitionType = cassetteBc.transitionType;
        request.transitionUrl = cassetteBc.transitionInfo;
        request.button_name = cassetteBc.buttonName;
        request.start_browser_flg = cassetteBc.startBrowserFlg;
        request.is_external_link = 1;
        request.shop_menu_id = cassetteBc.shopMenuId;
        request.cassetteId = this.newEditCassetteId;
        request.external_link_id = cassetteBc.externalLinkId;

        if (cassetteBc.titleColor) {
          request.titleColor = cassetteBc.titleColor;
        } else {
          delete request.titleColor;
        }
        if (cassetteBc.headline) {
          request.headline = cassetteBc.headline;
        } else {
          delete request.headline;
        }
        if (cassetteBc.headlineColor) {
          request.headline_color = cassetteBc.headlineColor;
        } else {
          delete request.headlineColor;
        }
      }
      //20201105 外部リンク追加対応 cyber 段 end
    }
    return request;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createBannerSortRequest() {
    const request = { shopId: Number(this.shopId) } as SortBannerRequest;
    request.ids = this.bannerTableItems.map(i => i.id);
    return request;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createCassetteASortRequest() {
    const request = {
      topType: CassetteGet.getTopType,
      shopId: Number(this.shopId)
    } as SortCassetteRequest;
    request.ids = this.cassetteATableItems.map(i => i.id);
    return request;
  }

  /**
   * dataから並び替え用のリクエストを作成する
   */
  private createCassetteBcSortRequest() {
    const request = {
      topType: CassetteGet.getTopType,
      shopId: Number(this.shopId)
    } as SortCassetteRequest;
    request.ids = this.cassetteBcTableItems.map(i => i.id);
    //20201105 外部リンク追加対応 cyber 段 start
    request.menuId = this.cassetteBcTableItems.map(i => i.menuId)
    //20201105 外部リンク追加対応 cyber 段 end
    return request;
  }
}

import service from "@/api/service";
import {
  GetRequest,
  SortRequest,
  DeleteRequest,
  DetailRequest,
  RegisterRequest
} from "@/api/banner/request";
import {
  GetResponse,
  DetailResponse,
  RegisterResponse
} from "@/api/banner/response";
import { CommonResponse } from "@/api/response";

/**
 * バナー一覧APIをコールします。
 *
 * @param getRequest バナー検索のリクエストボディ
 * @return GetResponse
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-banner", getRequest);
  return response.data as GetResponse;
}

/**
 * バナー詳細情報取得APIをコールします。
 *
 * @param detailRequest バナー詳細情報取得のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/get-banner-detail", detailRequest);
  return response.data as DetailResponse;
}

/**
 * バナー情報登録更新APIをコールします。
 *
 * @param registerRequest バナー情報登録更新のリクエストボディ
 * @return RegisterResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-banner", registerRequest);
  return response.data as RegisterResponse;
}

/**
 * バナー並び替え登録更新APIをコールします。
 *
 * @param sortRequest
 * @return CommonResponse
 */
export async function sort(sortRequest: SortRequest) {
  const response = await service.post("/sort-banner", sortRequest);
  return response.data as CommonResponse;
}

/**
 * バナー情報削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-banner", deleteRequest);
  return response.data as CommonResponse;
}

import {
  getModule,
  Module,
  VuexModule,
  MutationAction
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse, GetMenu } from "@/api/menu-information/response";
import { GetRequest } from "@/api/menu-information/request";
import * as MenuAPI from "@/api/menu-information";
import { isSuccess } from "@/api/response";

const MODULE_NAME = "menu-information/get";

/**
 * メニューのデコレーター
 */
export class ListItem {
  constructor(private _item: GetMenu) {}
  get id() {
    return this._item.id;
  }
  get imagePath() {
    return this._item.imagePath;
  }
  get name() {
    return this._item.name;
  }
  get description() {
    return this._item.description;
  }
  get displayName() {
    return this._item.displayName;
  }
  get menuViewType() {
    return Number(this._item.menuViewType) == 0 ? "利用しない" : "利用する";
  }
  get writeFlg() {
    return this._item.writeFlg;
  }
}

/**
 * メニュー一覧・検索API（/get-menuInfomation）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  searchResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.searchResponse);
  }

  get getMessage() {
    return this.searchResponse.message;
  }

  get getItems() {
    if (this.searchResponse.results) {
      return (this.searchResponse.results.menu || []).map(
        value => new ListItem(value)
      );
    } else {
      return [] as ListItem[];
    }
  }

  // MutationActions
  @MutationAction
  async get(getRequest: GetRequest) {
    const searchResponse = await MenuAPI.get(getRequest);
    return {
      searchResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      searchResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
